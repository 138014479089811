<!-- =========================================================================================
    File Name: ChatLog.vue
    Description: Chat Application - Log of chat
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<!-- hasSentPreviousMsg -->
<template>
  <div>
    <div
      class="absolute inset-x-0 top-0 flex items-center justify-center"
      v-if="loadingMoreMsgs"
    >
      <span class="material-icons-outlined animate-spin text-9xl"> sync </span>
    </div>
    <div
      id="component-chat-log"
      :class="[{ 'm-8': !contactClosed }, { 'm-8 pb-44': contactClosed }]"
      v-if="messageList"
    >
      <div
        v-for="(msg, index) in messageList"
        class="msg-grp-container"
        :key="`msg-${getMessageId(msg, index)}`"
      >
        <!-- If previouse msg is older than current time -->
        <vs-divider
          v-if="
            index === 0 ||
            !isSameDay(msg.timestamp, messageList[index - 1].timestamp)
          "
        >
          <span class="text-grey text-xs">{{ toDate(msg.timestamp) }}</span>
        </vs-divider>
        <div class="spacer mt-8" v-if="!hasSentPreviousMsg(true, true)"></div>

        <!-- Mensagens Enviadas -->
        <div>
          <div
            :class="getOrientation(msg.author, index)"
            class="text-grey text-xs mb-px"
          >
            {{ getParticipantName(msg.author) }}
          </div>
        </div>

        <div
          :id="`msg-${msg._id}`"
          class="flex items-center group relative z-auto overflow-visible"
          :class="[
            {
              'flex-row-reverse': getOrientationPos(msg.author) === true,
              'flex-row-center': getOrientationPos(msg.author) === 'log',
            },
          ]"
        >
          <div
            v-if="
              msg.reactions && (msg.reactions.operator || msg.reactions.client)
            "
            class="absolute -bottom-2 z-30"
          >
            <div class="reactions rounded-full px-1">
              <span v-if="msg.reactions.operator === msg.reactions.client">
                {{ msg.reactions.operator }} 2
              </span>
              <span v-else>
                {{ msg.reactions.client }}{{ msg.reactions.operator }}
              </span>
            </div>
          </div>
          <div
            class="msg msg-box break-words relative py-3 px-4 mb-2 rounded-lg max-w-sm shadow"
            :class="{
              'msg-sent': getOrientationPos(msg.author) === true,
              'bg-white': getOrientationPos(msg.author) === false,
              'msg-log': getOrientationPos(msg.author) === 'log',
              'msg-note': msg.author === 'notes',
              'msg-summary': isIaMessage(msg.author),
            }"
            v-if="msg.data !== undefined"
            :id="'msg_' + index"
          >
            <div
              v-if="
                msg.author !== 'notes' &&
                isCloudApi &&
                !getAuthorIsSystem(msg.author) &&
                !isIaMessage(msg.author)
              "
              class="w-full reply-opt h-1 flex justify-end z-50"
            >
              <vs-dropdown
                vs-custom-content
                class="self-center cursor-pointer mt-1"
                vs-trigger-click
                :key="dropdownKey"
              >
                <i class="cursor-pointer notranslate material-icons tiny"
                  >expand_more</i
                >
                <vs-dropdown-menu>
                  <vs-button
                    color="white"
                    style="color: black"
                    size="small"
                    @click="replyMessage(msg)"
                    >{{ $t("Respond") }}</vs-button
                  >
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>

            <!-- Message Reply -->
            <div
              class="msg-reply cursor-pointer"
              v-if="msg.reply"
              @click="($event) => gotoMessage(msg.reply._id)"
            >
              <div class="reply-participant">
                {{ getParticipantName(msg.author).replace(":", "") }}
              </div>
              <div v-if="msg.reply.type === 'file'">
                <span v-if="msg.reply.data.mimetype.includes('image')">
                  {{
                    (msg.reply.data.text !== "Media Received"
                      ? msg.reply.data.text
                      : undefined) ||
                    "Imagem " + toDateSmall(msg.reply.timestamp)
                  }}
                </span>
                <span v-else-if="msg.reply.data.mimetype.includes('audio')">
                  {{
                    (msg.reply.data.text !== "Media Received"
                      ? msg.reply.data.text
                      : undefined) ||
                    "Audio " + toDateSmall(msg.reply.timestamp)
                  }}
                </span>
                <span v-else-if="msg.reply.data.mimetype.includes('video')">
                  {{
                    (msg.reply.data.text !== "Media Received"
                      ? msg.reply.data.text
                      : undefined) ||
                    "Video " + toDateSmall(msg.reply.timestamp)
                  }}
                </span>
                <div v-else>{{ msg.reply.data.text || "File" }}</div>
              </div>
              <div v-if="msg.reply.type === 'text'">
                {{ msg.reply.data.text }}
              </div>
            </div>
            <div
              class="w-full float-left mb-4"
              v-if="msg && msg.author && isIaMessage(msg.author)"
            >
              <div class="tag-summary float-left flex items-center">
                <feather-icon
                  icon="FileTextIcon"
                  svgClasses="w-5"
                  class="mr-1"
                />
                {{ getIaMessageTitle(msg.author) }}
              </div>
            </div>

            <div
              class="w-full float-left mb-4"
              v-if="msg && msg.author && msg.author === 'notes'"
            >
              <div class="tag-note float-left flex items-center">
                <feather-icon
                  icon="ClipboardIcon"
                  svgClasses="w-5"
                  class="mr-1"
                />
                {{ $t("Note") }}
              </div>
            </div>

            <div v-if="msg.type === 'file' || msg.data.file">
              <div class="flex w-full relative mb-3">
                <div
                  class="image-area flex justify-center"
                  v-if="
                    (msg.data.mimetype &&
                      msg.data.mimetype.includes('image')) ||
                    (msg.data.file &&
                      getMimeType(msg.data.file).includes('image'))
                  "
                >
                  <img
                    class="myImg"
                    loading="lazy"
                    :src="getTempURL(msg)"
                    @click="imgZoom(getTempURL(msg))"
                    @error="setDummyImage"
                  />
                </div>
                <div
                  class="audio-area"
                  v-else-if="
                    (msg.data.mimetype &&
                      msg.data.mimetype.includes('audio')) ||
                    (msg.data.file &&
                      getMimeType(msg.data.file).includes('audio'))
                  "
                >
                  <audio controls preload="auto">
                    <source :src="getTempURL(msg)" />
                  </audio>
                </div>
                <div
                  class="flex flex-col items-center justify-center"
                  v-else-if="
                    (msg.data.mimetype &&
                      msg.data.mimetype.includes('video')) ||
                    (msg.data.file &&
                      getMimeType(msg.data.file).includes('video'))
                  "
                >
                  <span
                    :id="`btn_video_${msg.id}`"
                    class="material-icons absolute cursor-pointer text-white text-5xl z-50"
                    @click="playVideo(msg.id)"
                  >
                    play_circle
                  </span>
                  <video
                    controls
                    preload="none"
                    :id="`video_${msg.id}`"
                    width="100%"
                  >
                    <source
                      :type="msg.data.mimetype"
                      :id="`videoSource_${msg.id}`"
                      :src="getTempURL(msg)"
                    />
                  </video>
                </div>
                <div class="file-area flex" v-else-if="msg.data.file">
                  <div class="flex-auto material-icons text-4xl text-primary">
                    insert_drive_file
                  </div>
                  <div class="flex-auto flex items-center justify-center ml-1">
                    <a
                      :href="getTempURL(msg)"
                      target="_blank"
                      style="inline-size: 100%; overflow-wrap: break-word"
                      :title="getFileName(msg.data)"
                    >
                      {{ getFileName(msg.data) }}
                    </a>
                  </div>
                </div>
                <div
                  :style="
                    showDownloadButtonFloating(msg.data.mimetype)
                      ? 'background: rgba(0, 0, 0, 40%)'
                      : ''
                  "
                  class="flex p-1 mr-1 mb-1 rounded-full items-center cursor-pointer justify-center"
                  v-if="showDownloadButton(msg.data.mimetype)"
                  :class="{
                    'absolute right-0 bottom-0': showDownloadButtonFloating(
                      msg.data.mimetype
                    ),
                    'ml-1 mt-1': !showDownloadButtonFloating(msg.data.mimetype),
                  }"
                >
                  <a :href="getTempURL(msg)" target="_blank" class="h-5">
                    <span
                      class="material-icons text-xl"
                      :style="`enable-background: new 0 0 484.702 484.703;
                        ${
                          showDownloadButtonFloating(msg.data.mimetype)
                            ? 'color: rgba(255, 255, 255, 60%);'
                            : 'color: rgba(0, 0, 0, 60%);'
                        }`"
                    >
                      download
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div
              v-if="
                msg.data.text &&
                msg.data.text.length > 0 &&
                !(msg.data.mimetype && msg.data.mimetype.includes('audio'))
              "
              :class="{ 'mt-3': msg.type === 'file' }"
            >
              <div
                class="break-words w-full whitespace-pre-line"
                :class="{ 'text-center': msg.author === 'log' }"
                v-html="
                  treatMessageText(
                    msg.data.text,
                    msg.suggestions,
                    msg.type === 'file'
                  )
                "
              ></div>
              <div
                class="w-full text-xs text-center"
                v-if="getOrientationPos(msg.author) === 'log'"
              >
                {{ toDateSmall(msg.timestamp) }}
              </div>
            </div>
            <div
              class="text-xs flex text-grey items-center justify-between w-full gap-x-4"
              :class="{
                'gap-x-8':
                  isIaMessage(msg.author) ||
                  (msg.data.mimetype && msg.data.mimetype.includes('audio')),
              }"
              v-if="getOrientationPos(msg.author) !== 'log'"
            >
              <div
                class="transcriptAudio"
                v-if="
                  msg.data.mimetype &&
                  msg.data.mimetype.includes('audio') &&
                  accHasAIFeature &&
                  !msg.data.transcription &&
                  conversationStatus !== 4
                "
              >
                <vs-button
                  class="text-sm py-2 px-4 rounded-4xl"
                  @click="transcribeAudio(msg, index)"
                >
                  <span class="flex items-center gap-x-2">
                    <BlockquoteIcon />
                    {{ $t("Transcribe") }}
                  </span>
                </vs-button>
              </div>
              <div
                class="privateMessage w-full flex items-center"
                v-if="
                  (msg && msg.author && isIaMessage(msg.author)) ||
                  msg.author === 'notes'
                "
              >
                <feather-icon icon="InfoIcon" svgClasses="w-3" class="mr-1" />
                Nota Interna
              </div>
              <span class="whitespace-nowrap">
                {{ isIaMessage(msg.author) ? "Duotalk IA - " : "" }}
                {{ msg.author === "notes" ? `${msg.authorName} - ` : "" }}
                {{
                  isIaMessage(msg.author) || msg.author === "notes"
                    ? null
                    : getChannel(msg.channel) | capitalize
                }}{{ toDateSmall(msg.timestamp) }}
              </span>
              <template
                v-if="
                  msg &&
                  msg.author &&
                  !isIaMessage(msg.author) &&
                  msg.author !== 'notes' &&
                  getOrientationPos(msg.author)
                "
              >
                <i v-if="msg.sendOnGoing" class="ml-1">
                  <div
                    v-if="msg.type === 'file' && msg.fileUploadPercent >= 0"
                    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"
                  >
                    <upload-circular-progress
                      :progress="msg.fileUploadPercent"
                    />
                  </div>
                  <span class="material-icons text-sm flex items-center">
                    schedule
                  </span>
                </i>
                <i
                  v-else-if="
                    msg.ack === -1 ||
                    (msg.ack === 0 && messageNoAckResponseArr.includes(msg.id))
                  "
                  class="cursor-pointer ml-1"
                  @click="resendMsg(msg, index)"
                >
                  <div v-if="msg.resent">
                    <div data-tooltip="Não foi possível enviar.">
                      <span
                        class="material-icons-outlined text-sm text-danger flex items-center"
                      >
                        error
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      data-tooltip="Não foi possível enviar. Clique para tentar novamente."
                    >
                      <span
                        class="material-icons-outlined text-sm text-danger flex items-center"
                      >
                        error
                      </span>
                    </div>
                  </div>
                </i>
                <i v-else-if="msg.ack <= -2" class="cursor-not-allowed">
                  <div
                    :data-tooltip="
                      ackBlockedMessage(msg.ack, msg.deliveryMessage)
                    "
                    class="flex items-center"
                  >
                    <span
                      class="material-icons text-danger text-sm ml-1 flex items-center"
                    >
                      block
                    </span>
                  </div>
                </i>
                <i
                  v-else-if="msg.ack === 1"
                  class="material-icons text-base ml-1 flex items-center"
                  >done</i
                >
                <i
                  v-else-if="msg.ack === 2"
                  class="material-icons text-base ml-1 flex items-center"
                  >done_all</i
                >
                <i
                  v-else-if="msg.ack === 3"
                  class="material-icons text-base text-crystalBlue ml-1 flex items-center"
                >
                  done_all
                </i>
              </template>
            </div>
            <div
              v-if="
                msg.data.mimetype &&
                msg.data.mimetype.includes('audio') &&
                msg.data.transcription &&
                msg.data.transcription.text &&
                accHasAIFeature
              "
            >
              <vs-divider />
              <div class="w-full float-left">
                <div class="tagTranscript float-left flex items-center gap-x-2">
                  <BlockquoteIcon />
                  {{ $t("Transcript") }}
                </div>
              </div>
              <p class="float-left mt-5 text-grey text-base">
                {{
                  msg.data.transcription.text.length > 200 &&
                  !msg.data.transcription.expand
                    ? msg.data.transcription.text.substring(0, 200) + "..."
                    : msg.data.transcription.text
                }}
                <button
                  :ref="'transcriptedAdudioButton-' + index"
                  v-if="
                    msg.data.transcription.text.length > 200 &&
                    !msg.data.transcription.expand
                  "
                  class="text-primary border-none bg-transparent cursor-pointer hover:underline"
                  @click="
                    msg.data.transcription.expand =
                      !msg.data.transcription.expand
                  "
                >
                  {{ $t("SeeFullTranscript") }}
                </button>
              </p>
            </div>
          </div>

          <!-- Reaction -->
          <div
            class="relative justify-center emoji"
            @click="showEmojis($event, msg)"
            v-if="
              isCloudApi &&
              !getAuthorIsSystem(msg.author) &&
              conversationStatus != 4
            "
          >
            <div :id="`emojiArea_${msg.id}`"></div>
            <div class="flex justify-end emoji">
              <vs-button
                size="large"
                type="flat"
                icon="add_reaction"
                radius
                class="emoji mx-2"
                :class="
                  msg.id === messageClicked.id
                    ? null
                    : 'opacity-0 group-hover:opacity-100'
                "
              >
              </vs-button>
            </div>
          </div>
        </div>
      </div>

      <div id="emojiArea" class="hidden">
        <div
          class="emoji flex items-center rounded-full shadow-md bg-white p-2 absolute bottom-16 left-0"
        >
          <div :key="emoji" v-for="emoji in emojis">
            <div class="emoji cursor-pointer font-medium mx-1">
              <vs-button
                class="emoji p-1"
                :type="
                  messageClicked &&
                  messageClicked.reactions &&
                  messageClicked.reactions.operator &&
                  messageClicked.reactions.operator === emoji
                    ? 'filled'
                    : 'flat'
                "
                color="primary"
                size="small"
                radius
              >
                <span class="text-xl emoji emoji-choosed">{{ emoji }}</span>
              </vs-button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="contactClosed" class="!h-48"></div>
    </div>

    <!-- The IMG Modal -->
    <div
      ref="imgExpandModal"
      class="hidden fixed top-0 left-0 z-80 w-screen h-screen bg-black bg-opacity-70 justify-center items-center imgModal"
      @click="imgZoom(null, true)"
    >
      <a
        class="fixed z-90 top-6 right-8 text-white text-5xl font-bold"
        href="javascript:void(0)"
        @click="imgZoom(null, true)"
        >&times;</a
      >
      <img
        ref="modalImg"
        @mousemove="zoomImage"
        @click.stop="zoomImageClick"
        class="zoom object-cover imgModalZoom absolute"
      />
    </div>
    <div
      class="msg-reply reply-fixed flex justify-between cursor-pointer"
      v-if="replyMsg"
    >
      <div>
        <div class="reply-participant">
          {{ getParticipantName(replyMsg.author).replace(":", "") }}
        </div>
        <div v-if="replyMsg.type === 'file'">
          <span v-if="replyMsg.data.mimetype.includes('image')">
            {{
              (replyMsg.data.text !== "Media Received"
                ? replyMsg.data.text
                : undefined) || "Imagem " + toDateSmall(replyMsg.timestamp)
            }}
          </span>
          <span v-else-if="replyMsg.data.mimetype.includes('audio')">
            {{
              (replyMsg.data.text !== "Media Received"
                ? replyMsg.data.text
                : undefined) || "Audio " + toDateSmall(replyMsg.timestamp)
            }}
          </span>
          <span v-else-if="replyMsg.data.mimetype.includes('video')">
            {{
              (replyMsg.data.text !== "Media Received"
                ? replyMsg.data.text
                : undefined) || "Video " + toDateSmall(replyMsg.timestamp)
            }}
          </span>
          <div v-else>{{ replyMsg.data.text || "File" }}</div>
        </div>
        <div v-if="replyMsg.type === 'text'">
          {{ replyMsg.data.text }}
        </div>
      </div>

      <vs-button
        @click="closeReplyMessage"
        type="flat"
        radius
        color="dark"
        icon-pack="feather"
        icon="icon-x"
      />
    </div>
  </div>
</template>

<script>
import BlockquoteIcon from "@/components/svg/BlockquoteIcon.vue";
import axios from "axios";
import mime from "mime/lite";
import UploadCircularProgress from "./UploadCircularProgress.vue";

const fileTypes = require("./fileTypes.json");

export default {
  components: { BlockquoteIcon, UploadCircularProgress },
  data() {
    return {
      emojiSelected: false,
      emojis: ["😂", "👍", "🙏", "❤️", "😕"],
      emojiClicked: false,
      messageClicked: {},
      reactionMsgID: null,
      dropdownKey: 99999,
      messageNoAckResponseArr: [],
      intervalCheckACK: "",
      isLoadingMedia: false,
      isVideoDownloaded: false,
      isVideoPlaying: false,
      zoomImageClicked: false,
      lastMessagesListPage: 1,
      newMessagesReceived: false,
      msgPerPage: 10,
      loadingMoreMsgs: false,
      transcribingAudio: false,
    };
  },
  props: {
    cloudChats: {
      type: Array,
      required: false,
      default: () => [],
    },
    userId: {
      type: [String, Number],
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
    participants: {
      type: Array,
      required: true,
    },
    contactName: {
      type: String,
      required: true,
    },
    contactClosed: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    messageListHistory: {
      type: Array,
      required: false,
    },
    messagesListPage: {
      type: Number,
      required: false,
      default: () => 1,
    },
    scrollMessagesListToBottom: {
      type: Number,
      required: false,
      default: () => 1,
    },
    conversationStatus: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  computed: {
    messageListFull() {
      return this.messageListHistory || this.$store.state.chat.messageList;
    },
    messageList() {
      if (this.contactClosed) return this.messageListFull;
      const total = this.messageListFull.length;
      return Array.isArray(this.messageListFull)
        ? this.messageListFull.slice(
            total - (this.messagesListPage + 1) * this.msgPerPage <= 0
              ? 0
              : total - (this.messagesListPage + 1) * this.msgPerPage,
            total
          )
        : [];
    },
    channel() {
      return this.$store.state.chat.channel;
    },
    isCloudApi() {
      return (
        this.channel === "WhatsApp 360" &&
        this.cloudChats.includes(this.contact.chatID)
      );
    },
    replyMsg() {
      return this.$store.state.chat.chatReplyMessage;
    },
    acc() {
      return this.$store.state.acc.current_acc.id;
    },
    apiURL() {
      return process.env.VUE_APP_API_URL;
    },
    activeUserInfo() {
      return this.$store.state.user;
    },
    contactNameInitials() {
      var initials = this.name.match(/\b\w/g) || [];
      return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
    },
    chatData() {
      return this.$store.getters["chat/chatDataOfUser"](this.userId);
    },
    activeUserImg() {
      return this.$store.state.user.img;
    },
    hasSentPreviousMsg() {
      return (last_sender, current_sender) => last_sender === current_sender;
    },
    accHasAIFeature() {
      return (
        this.$store.state.acc.current_acc.aiEnable ||
        this.acc === "60ad40a8cf24431d122b2433" ||
        this.acc === "608c60d25e9671ec25384820" ||
        this.acc === "5f5a4806e7a7bc318ae589cc" ||
        this.acc === "64e7286b799c8d04217445ee" ||
        this.acc === "626a9da67edc070332477a55" ||
        this.acc === "626a9d947edc07e34c4776a1" ||
        this.acc === "64f774f21bb62e5a0f0ae7be" ||
        this.acc === "634f2828d73776a6886df536" ||
        this.acc === "638ddbce63ef447b55b5f6b1" ||
        this.acc === "63286decb79ebf3b3384367e" ||
        this.acc === "6298bbf57d445a5dec90e060" ||
        this.acc === "62c5a4e1f45755f275bd8d1d" ||
        this.acc === "62c5a4e1f45755f275bd8d1d" ||
        this.acc === "623b367aadbb4d4aa6802372" ||
        this.acc === "63a36fd60a079cd7b75de1ed" ||
        this.acc === "63a36fe60a079c2e6d5de573" ||
        this.acc === "647de082c17f5f6bc518bcca" ||
        this.acc === "647de0c5f88ea8e8edbbd3dd" ||
        this.acc === "647de0ddfdf35443e301ebcf" ||
        this.acc === "647de0f4fdf354294f01ef36" ||
        this.acc === "647de106f88ea8652cbbde82" ||
        this.acc === "647de11ed44b0d4ad326c9d2" ||
        this.acc === "647de131821e83a789e02735" ||
        this.acc === "647de37afdf354c84502809b" ||
        this.acc === "647de399d44b0d36682757c2" ||
        this.acc === "647de3af7c43cdc9d2c25192" ||
        this.acc === "647e12ed2e3fd754b5375ef1" ||
        this.acc === "648c67528f79849e304b2205" ||
        this.acc === "64a5870d3ef4914948a88910" ||
        this.acc === "64a5871e997f109a046f4c4c" ||
        this.acc === "6310a6c603f50d057e202d02" ||
        this.acc === "6310a75f03f50d65af207d68" ||
        this.acc === "63a36faa51d3cd17c1e400a3" ||
        this.acc === "63ab38651b5aadfa16ab87b6" ||
        this.acc === "63efc042b2d31059ad35d66e"
      );
    },
  },
  watch: {
    scrollMessagesListToBottom() {
      this.scrollToBottom();
    },
    messagesListPage(val) {
      //calc scrollTop
      if (val === 0 || val * this.msgPerPage >= this.messageListFull.length) {
        this.loadingMoreMsgs = false;
        return;
      }
      this.loadingMoreMsgs = true;
      let totalHeight = 0;
      const msgsElements = document.getElementsByClassName("msg-grp-container");
      for (let i = 0; i < this.msgPerPage; i += 1) {
        if (msgsElements[i]) totalHeight += msgsElements[i].offsetHeight;
      }

      this.$emit("chat-log-scroll-top", totalHeight);
      this.loadingMoreMsgs = false;
    },
    emojiClicked(val) {
      if (!val) this.messageClicked = {};
    },
    messageClicked(val, oldVal) {
      const emojiArea = document.getElementById("emojiArea");
      if (emojiArea) {
        const emojiAreaChild = document.getElementById(`emojiArea_${val.id}`);
        if (emojiAreaChild) emojiAreaChild.innerHTML = emojiArea.innerHTML;
        const oldEmojiAreaChild = document.getElementById(
          `emojiArea_${oldVal.id}`
        );
        if (oldEmojiAreaChild) oldEmojiAreaChild.innerHTML = "";
      }
    },
    messageList(val, oldval) {
      this.messageList.map((msg) => {
        // Adiciona um novo campo ao objeto mensagem chamado reply
        if (msg.context) {
          const msgReply = this.messageList.find(
            (msgL) => msgL.id === msg.context.id
          );
          if (msgReply) {
            msg.reply = msgReply;
          }
        }

        return msg;
      });

      if (
        !this.contactClosed &&
        oldval.length > 0 &&
        val.length > oldval.length &&
        this.lastMessagesListPage === this.messagesListPage
      )
        this.newMessagesReceived = true;
    },
  },
  methods: {
    getMimeType(path) {
      return mime.getType(path) || "";
    },
    getMessageId(msg, index) {
      return (
        msg._id ||
        msg.uid ||
        `${new Date().getTime()}_${
          index * Math.random() * 10000
        }_${Math.random()}`
      );
    },
    showEmojis(e, msg) {
      this.emojiClicked = true;
      this.messageClicked = msg;
    },
    setEmoji(emoji) {
      const messageId = this.messageClicked.id;
      if (messageId && !this.emojiSelected) {
        this.emojiSelected = true;
        const thisIns = this;
        setTimeout(() => {
          thisIns.$emit("onReaction", messageId, emoji);
          thisIns.emojiClicked = false;
          thisIns.emojiSelected = false;
        }, 50);
      }
    },
    handlePageClick(e) {
      // verificando se o click foi dentro do botão de reação
      // e ignora
      if (e.target && e.target.classList && e.target.parentElement) {
        if (e.target.classList.contains("emoji-choosed")) {
          if (!this.emojiSelected) {
            this.setEmoji(e.target.innerText);
            this.emojiSelected = true;
          }
        } else if (
          e.target.classList.contains("emoji") ||
          e.target.parentElement.classList.contains("emoji")
        )
          return;
      }

      this.emojiClicked = null;
      this.emojiSelected = false;
    },
    handleScroll() {
      this.dropdownKey = Math.random();
    },
    async replyMessage(message) {
      this.dropdownKey = Math.random();
      await this.$store.dispatch("chat/setChatReplyMessage", message);
      setTimeout(() => {
        this.scrollToBottom();
      }, 50);
      this.$emit("onReplyMessage", null);
    },
    async closeReplyMessage() {
      await this.$store.dispatch("chat/setChatReplyMessage", null);
    },
    ackBlockedMessage(ack, deliveryMessage) {
      switch (ack) {
        case -2:
          return "Não é possível enviar. Esse número de telefone é inválido.";
        case -3:
          return "Não é possível enviar, arquivo não suportado pelo WhatsApp.";
        case -4:
          return (
            deliveryMessage ||
            "Não foi possível enviar. Entre em contato com nosso suporte."
          );
        case -5:
          return this.$t("Wpp24hWindowError");
      }
    },
    getAuthorIsSystem(author) {
      return author === "log" || author === "System";
    },
    getOrientationPos(idUser) {
      if (idUser === "me") return false;
      else if (idUser === "log") return "log";
      else if (idUser === "System") return "Sistema";
      else return true;
    },
    getOrientation(idUser, i) {
      let hidden = "";
      if (
        this.messageList[i - 1] &&
        (this.messageList[i - 1].author === idUser ||
          (this.messageList[i - 1].author === "assistant" &&
            idUser === "system"))
      )
        hidden = " hidden";
      if (idUser === "me") return "text-left" + hidden;
      else return "text-right" + hidden;
    },
    getParticipantName(id) {
      if (id === "me") return `${this.contactName}:`;
      if (id === "assistant") return "Duotalk Bot:";
      if (id === "system") return "Duotalk Bot:";
      if (id === this.activeUserInfo._id) {
        return this.activeUserInfo.name.length > 20
          ? `${this.activeUserInfo.name.slice(0, 20)}:`
          : `${this.activeUserInfo.name}:`;
      }
      var found = this.participants.find((e) => e.id === id);
      return found ? found.name : "";
    },
    isSameDay(time_to, time_from) {
      const date_time_to = new Date(time_to);
      const date_time_from = new Date(time_from);
      return (
        date_time_to.getFullYear() === date_time_from.getFullYear() &&
        date_time_to.getMonth() === date_time_from.getMonth() &&
        date_time_to.getDate() === date_time_from.getDate()
      );
    },
    getLocale() {
      if (navigator.languages !== undefined) return navigator.languages[0];
      if (navigator.language !== undefined) return navigator.language;
    },
    toDate(time) {
      const locale = this.getLocale();
      const date_obj = new Date(time);
      const monthName = date_obj.toLocaleString(locale, {
        month: "short",
      });
      return date_obj.getDate() + " " + monthName;
    },
    toDateSmall(time) {
      let fullDate = new Date(time);
      let valid = new Date(time).getTime() > 0;
      if (!valid) fullDate = new Date(time);
      const dd = String(fullDate.getDate()).padStart(2, "0");
      const mm = String(fullDate.getMonth() + 1).padStart(2, "0");
      const yy = String(fullDate.getFullYear()).substring(2, 4);
      let hour = fullDate.getHours() + ":";
      if (fullDate.getMinutes() < 10) hour += "0" + fullDate.getMinutes();
      else hour += fullDate.getMinutes();
      return `${dd}/${mm}/${yy} - ${hour}`;
    },
    gotoMessage(id) {
      const elm = document.getElementById(`msg-${id}`);
      if (elm) {
        elm.classList.add("divShaker");
        elm.scrollIntoView();
        setTimeout(() => {
          elm.classList.remove("divShaker");
        }, 1000);
      }
    },
    treatMessageText(text, suggestions, isFile = false) {
      if (!text) return "";
      if (text === "Media Received" && isFile) return "";

      if (text) {
        let suggestionsTxt = "";
        if (suggestions && suggestions.length > 0) {
          suggestionsTxt += "<br /> - ";
          suggestionsTxt += suggestions.join("<br /> - ");
        }

        text = text.replace(
          /[&<>'"]/g,
          (tag) =>
            ({
              "&": "&amp;",
              "<": "&lt;",
              ">": "&gt;",
              "'": "&#39;",
              '"': "&quot;",
            }[tag] || tag)
        );

        let newText = text;

        const links = text.match(/(https?:\/\/|www.).[^\s]+/g);
        if (links) {
          links.forEach((link) => {
            newText = newText.replace(
              link,
              link.includes("/csat/") || link.includes("/nps/")
                ? ` <i>${this.$t("LinkRemovedSecured")}</i>`
                : ` <a href="${
                    !link.includes("http") ? `//${link}` : link
                  }" target="_blank">${link}</a>`
            );
          });
        }

        const regex = /\*(.*?)\*/g;
        const result = regex.test(newText);
        if (result) {
          newText = newText.replace(/\*(.*?)\*/g, "<b>$1</b>");
          newText = newText.replace(/\*:\n/g, "</b>:<br>");
        }
        return `${newText.trim()} ${suggestionsTxt}`;
      }
    },
    toDateLarge(time) {
      let fullDate = new Date(time);
      let valid = new Date(time).getTime() > 0;
      if (!valid) fullDate = new Date(time);
      let hour = fullDate.getHours() + ":";
      if (fullDate.getMinutes() < 10) hour += "0" + fullDate.getMinutes();
      else hour += fullDate.getMinutes();
      hour += `:${fullDate.getSeconds()}`;
      return hour;
    },
    toHour(time) {
      let fullDate = new Date(Date.parse(time));
      let valid = new Date(time).getTime() > 0;
      if (!valid) fullDate = new Date(time);
      let hour = fullDate.getHours() + ":";
      if (fullDate.getMinutes() < 10) hour += "0" + fullDate.getMinutes();
      else hour += fullDate.getMinutes();
      return hour;
    },
    scrollToBottom() {
      this.newMessagesReceived = false;
      this.$nextTick(() => {
        if (this.$parent.$refs && this.$parent.$refs.chatLog)
          this.$parent.$refs.chatLog.scrollTop =
            this.$parent.$refs.chatLog.scrollHeight;
      });
    },
    messageNoAckResponse() {
      if (!this.contact.closed)
        this.messageList.forEach((message) => {
          const messageTime = new Date(message.timestamp).getTime();
          const time = new Date().getTime();
          const resultInSecs = Math.round((time - messageTime) / 1000);
          const index = this.messageNoAckResponseArr.findIndex(
            (el) => el === message.id
          );
          if (resultInSecs > 10 && !message._id) {
            if (index === -1) this.messageNoAckResponseArr.push(message.id);
          } else if (resultInSecs > 60 && index === -1)
            this.messageNoAckResponseArr.push(message.id);
          else if (index > -1 && resultInSecs < 60)
            this.messageNoAckResponseArr.splice(index, 1);
        });
    },
    resendMsg(msg, index) {
      if (this.messageList[index].resent) return;
      else this.messageList[index].resent = true;
      if (!this.contact.closed) {
        const compareMessage = (messageItem) => {
          return messageItem.id === msg.id;
        };
        const messageFoundedIndex =
          this.$store.state.chat.messageList.findIndex(compareMessage);
        this.$store.dispatch("chat/updateMessageOnMessageList", {
          messageID: this.$store.state.chat.messageList[messageFoundedIndex].id,
          payload: { ack: 0, data: { ...msg.data, fileStatus: 2 } },
        });
        this.$emit("resend-msg", msg);
        const index = this.messageNoAckResponseArr.findIndex(
          (el) =>
            el === this.$store.state.chat.messageList[messageFoundedIndex].id
        );
        if (index > -1) this.messageNoAckResponseArr.splice(index, 1);
      }
    },
    getChannel(channel) {
      if (channel !== undefined && channel.length > 0)
        return `${channel.substring(0, 10)} - `;
    },
    getFileName(data) {
      if (data.filename) return data.filename;
      const fileUrlSplited = data.file.split("/");
      const fileNameSplited =
        fileUrlSplited[fileUrlSplited.length - 1].split("-");
      return fileNameSplited[fileNameSplited.length - 1];
    },
    getFileURL(status, message, fileURL = false) {
      this.isLoadingMedia = true;
      this.$emit("download-message-file", status, message, fileURL);
      this.isVideoDownloaded = true;
    },
    async playVideo(messageID) {
      const videoOBJ = document.getElementById(`video_${messageID}`);
      const videoSourceOBJ = document.getElementById(
        `videoSource_${messageID}`
      );
      const e = document.getElementById(`btn_video_${messageID}`);
      if (e) e.remove();
      if (videoOBJ && videoSourceOBJ) {
        await videoOBJ.load();
        await videoOBJ.play();
      }
    },
    showDownloadButton(mime) {
      return !!(
        mime &&
        (mime.includes("image") || mime.includes("application"))
      );
    },
    showDownloadButtonFloating(mime) {
      return !!(mime && mime.includes("image"));
    },
    zoomImageClick(e) {
      this.zoomImageClicked = !this.zoomImageClicked;

      let zoomer = e.currentTarget;
      if (!this.zoomImageClicked) {
        zoomer.style.transform = "unset";
      } else zoomer.style.transform = "scale(2)";
    },
    zoomImage(e) {
      if (!this.zoomImageClicked) return;
      let x;
      let y;
      let zoomer = e.currentTarget;
      const width = zoomer.clientWidth;
      const height = zoomer.clientHeight;

      x = width / 2 - e.offsetX;
      y = height / 2 - e.offsetY;
      zoomer.style.transform = `translateX(${x}px) translateY(${y}px) scale(2)`;
    },
    getTempURL(msg) {
      if (msg.data.fileUrl) return msg.data.fileUrl;
      let url;
      const loalPics = this.$store.state.chat.messageListLocalPics;
      const index = loalPics.findIndex((el) => {
        return msg.id === el.id;
      });
      if (index > -1) url = loalPics[index].url;
      else if (msg.data.filename)
        url = `${this.apiURL}p/chat/tempFile/${msg._id}/${msg.data.filename}?acc=${this.acc}&token=${this.$store.state.auth.accessToken}`;
      else url = `${this.apiURL}p/chat/media/tempFile/${msg.data.file}`;
      return url;
    },
    async downloadImage(url, filename, mime) {
      this.isLoadingMedia = true;
      this.$vs.loading();
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: mime });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download =
        !filename || filename === "undefined"
          ? `${new Date().getTime()}.${fileTypes[mime]}`
          : `${filename}`;
      link.click();
      URL.revokeObjectURL(link.href);
      this.$vs.loading.close();
    },
    imgZoom(src, out = false) {
      this.zoomImageClicked = false;
      const modal = this.$refs.imgExpandModal;
      if (out && modal) {
        modal.classList.add("hidden");
        return;
      }

      if (modal) modal.classList.remove("hidden");

      const modalImg = this.$refs.modalImg;
      if (modalImg) {
        modalImg.src = src;
        modalImg.style.transform = "unset";
        modalImg.style.position = "unset";
        modalImg.style.right = "unset";
        modalImg.style.top = "unset";
      }
    },
    setDummyImage(e) {
      e.srcElement.src = require("@/assets/images/notfounded2.png");
    },
    isIaMessage(author) {
      const iaAuthors = ["chatSummary", "sentimentAnalysis"];
      return iaAuthors.includes(author);
    },
    getIaMessageTitle(author) {
      switch (author) {
        case "chatSummary":
          return "Resumo da Conversa";
        case "sentimentAnalysis":
          return "Análise de Sentimento";
        default:
          return "";
      }
    },
    async transcribeAudio(msg, index) {
      window.analytics.track(
        "Transcrever Audio",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );

      this.$vs.loading();
      this.transcribingAudio = true;
      const transcription = {};

      try {
        const generatedMessage = await this.$http.post(
          "/p/chat/ai/transcribe/file",
          {
            url: this.getTempURL(msg),
            conversation: msg,
          }
        );

        if (
          generatedMessage &&
          generatedMessage.data &&
          generatedMessage.data.data
        ) {
          transcription.text = generatedMessage.data.data;
          transcription.expand = false;

          this.messageList[index] = {
            ...this.messageList[index],
            data: {
              ...this.messageList[index].data,
              transcription,
            },
          };

          await this.$store.dispatch(
            "chat/addMessageList",
            this.messageList[index]
          );
        }
        this.$vs.loading.close();
      } catch (err) {
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: this.$t("ErrorOnGeneratingChatGptSuggestion"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        this.transcribingAudio = false;
        this.$vs.loading.close();
      }
    },
  },
  updated() {
    this.isLoadingMedia = false;
    if (
      this.lastMessagesListPage === this.messagesListPage &&
      !this.transcribingAudio
    )
      this.scrollToBottom();
    this.lastMessagesListPage = this.messagesListPage;
  },
  created() {
    const thisIns = this;
    document.addEventListener("keydown", function (e) {
      let keyCode = e.key;
      if (keyCode === "Escape") thisIns.imgZoom(null, true);
    });
  },
  mounted() {
    this.scrollToBottom();
    document.removeEventListener("click", this.handlePageClick);
    document.addEventListener("click", this.handlePageClick);
  },
  beforeDestroy() {
    this.$store.dispatch("chat/setMessageList", []);
    document.removeEventListener("click", this.handlePageClick);
  },
};
</script>
<style lang="scss">
.reactions {
  background-color: #4f4d5f2d;
}
.msg-reply {
  padding: 5px;
  background-color: #7267f02d;
  border-radius: 5px;
  margin-bottom: 5px;
  border-left: 8px solid #7367f0;

  &.reply-fixed {
    width: calc(100% - 349px);
    position: absolute;
    bottom: 0;
    background: #e3e0f9;
    margin-bottom: 0;
    z-index: 999;
  }

  .reply-participant {
    color: #7367f0;
  }
}

.msg-box {
  .reply-opt {
    width: calc(100% - 2rem);
    opacity: 0;
    transition: linear all 0.2s;
  }

  &:hover {
    .reply-opt {
      opacity: 1;
    }
  }

  transition: 100ms;
}

.myImg {
  border-radius: 5px;
  cursor: zoom-in;
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
}

.myImg:hover {
  opacity: 0.7;
}

.image-area {
  min-width: 230px;
  min-height: 50px;
}

/* The Modal (background) */
.imgModal {
  z-index: 2147483648;
  padding: 5%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.imgModalZoom {
  cursor: zoom-in;
  max-height: 100%;
  max-width: 100%;
  transition: transform 300ms cubic-bezier(0.1, 0.82, 0.25, 1) 0s;
}

.flex-row-center {
  font-weight: 300;
  font-size: 12px;
  line-height: 1.2;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  font-style: italic;
  opacity: 0.55;
  justify-content: center;
}

.to-bottom {
  font-size: 12px;
  position: absolute;
  color: #999;
  bottom: 0px;
  right: 5px;
  display: inline-flex;
  justify-content: space-between;
  align-content: center;
}

.to-bottom i {
  margin-left: 6px;
}

.msg-sent {
  background-color: #7267f02d;
  color: black;
}

.msg-log {
  background-color: #fff0999d;
  color: black;
}

.msg-log {
  background-color: #fff0999d;
  color: black;
}

.msg-summary {
  background-color: rgba(255, 210, 0, 0.07);
  border: 1px solid #ffd200;
  color: black;
}
.tag-summary {
  background: #ffd200;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
}

.msg-note {
  background-color: #e4eef7;
  color: black;
  border: 1px solid #2620f6;
}
.tag-note {
  background: #2620f6;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  color: white;
}

.tagTranscript {
  border: 1px solid rgba(115, 103, 240, 1);
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  color: rgba(115, 103, 240, 1);

  svg path {
    stroke: rgba(115, 103, 240, 1) !important;
  }
}

.privateMessage {
  font-size: 10px;
  color: rgba(151, 151, 151, 1);
}

.spinner {
  animation: spin-animation 0.5s infinite;
  animation-direction: reverse;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

[data-tooltip]:before {
  position: absolute;
  content: attr(data-tooltip);
  opacity: 0;
  background-color: rgba(229, 62, 37, 80%);
  border-radius: 5px;
  color: #fff;
  padding: 10px;
  width: 200px;
  right: 0px;
  bottom: 20px;
}

[data-tooltip]:hover:before {
  opacity: 1;
}

[data-tooltip]:not([data-tooltip-persistent]):before {
  pointer-events: none;
}

.divShaker {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
